<template>
  <v-container fluid>
    <v-flex auto>
      <v-tabs
          v-model="tab"
          fixed-tabs
          background-color="primary"
          dark
          class="ma-2"
      >
        <v-tab>
          GỬI THÔNG BÁO
        </v-tab>
        <v-tab>
          THÔNG BÁO ĐÃ GỬI
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card>
            <v-toolbar color="#0f2e8c" flat>
              <h3 class="white--text">GỬI THÔNG BÁO</h3>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-row>
                <v-col
                  md="4"
                  lg="4"
                >
                  <v-text-field
                      v-model="request.title"
                      outlined
                      class="mx-2 row"
                      label="Tiêu đề tin nhắn"
                  />
                  <v-switch
                    v-model="request.mode"
                    class="mx-2 row"
                    :label="`Chế độ gửi: ${request.mode ? 'Tất cả':'Tuỳ chọn'}`"
                  />
                  <v-autocomplete
                      v-model="selectedCustomer"
                      :items="listCustomer"
                      class="mx-2 row"
                      label="Danh sách khách hàng"
                      multiple
                      chips
                      filled
                      v-if="request.mode === false"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click:close="doRemoveCustomerList(data.item)"
                      >
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                    md="8"
                    lg="8"
                >
                  <v-textarea
                      v-model="request.content"
                      outlined
                      class="mx-2 row"
                      label="Nội dung tin nhắn"
                  />
                  <div>
                    <span class="text-h4 ma-2">
                      Nội dung chi tiết
                    </span>
                    <quill-editor
                        v-model="request.full"
                        label="Nội dung"
                        style="height: 200px"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  color="primary"
                  @click="doSend"
                  :loading="loading"
                  :disabled="loading || (!request.title || !request.content) || (request.mode === false && selectedCustomer.length === 0)"
                  :dark="loading"
              >
                {{loading ? 'Đang gửi...':'Gửi'}}
              </v-btn>
            </v-card-actions>
          </v-card>
          <template v-if="SendUsers.length > 1">
            <v-divider
              class="ma-2"
            />
            <v-card>
              <v-toolbar color="#0f2e8c" flat>
                <h3 class="white--text">DANH SÁCH TIN NHẮN</h3>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-data-table
                    :items="SendUsers"
                    :headers="headers"
                >
                  <template v-slot:[`item.Status`]="{ item }">
                    {{ sendState[item.value] }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </template>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-toolbar color="#0f2e8c" flat>
              <h3 class="white--text">DANH SÁCH THÔNG BÁO ĐÃ GỬI</h3>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-data-table
                  :items="items"
                  :headers="headersData"
              >
                <template v-slot:[`item.Customer`]="{ item }">
                  <template v-if="!item.Customer">
                    Tất cả
                  </template>
                  <template v-else>
                    {{ item.Customer.Username }}
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-flex>
  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import Request from '@/plugins/query/snotification';
import {Status} from "@/plugins/helper/dstatus";
export default {
  name: "Notification",
  components:{
    quillEditor,
  },
  data(){
    return {
      tab: 0,
      loading: false,
      request:{
        mode: true,
        title: '',
        content: '',
        full: '',
      },
      listSent:[],
      listCustomer: [],
      selectedCustomer: [],
      sendState:{},
      items:[],
    }
  },
  computed:{
    headers(){
      return [
        { "text": "Khách hàng", "align": "center", "sortable": false, "value": "text", "width":"20%", "class":"grey lighten-2 black--text" },
        { "text": "Trạng thái", "align": "center", "sortable": false, "value": "Status", "width":"80%", "class":"grey lighten-2 black--text" },
      ]
    },
    headersData(){
      return [
        { "text": "Tiêu đề", "align": "center", "sortable": false, "value": "Title", "width":"20%", "class":"grey lighten-2 black--text" },
        { "text": "Nội dung", "align": "center", "sortable": false, "value": "Content", "width":"50%", "class":"grey lighten-2 black--text" },
        { "text": "Khách hàng", "align": "center", "sortable": false, "value": "Customer", "width":"30%", "class":"grey lighten-2 black--text" },
      ]
    },
    notice(){ return new Status(this.$swal)},
    SendUsers(){
      let users = [];
      this.selectedCustomer.forEach(item => {
        const index = this.listCustomer.findIndex(user => user.value === item);
        if(index !== -1) users.push(this.listCustomer[index]);
      });
      return users.map(item=>({
        ...item,
        state: this.sendState[item.value] ? this.sendState[item.value] : 'Chờ...',
      }));
    }
  },
  methods:{
    ...Request,
    doFetchData(){
      return this.qrList().then(json=>{
        if(json.data) this.items = json.data;
      });
    },
    doSend(){
      const {request} = this;
      this.sendState = {};
      if(request.mode === true){
        this.loading = true;
        this.qrSendAll(this.request).then(resp=>{
          if(resp.data){
            app.notice.success(`Đã gửi tin nhắn - Thành công: ${resp.data['success']} - Thất bại: ${resp.data['failure']}`);
          }else{
            app.notice.error(resp.error);
          }
        }).finally(()=>{
          this.loading = false;
        });
      }else{
        if(this.selectedCustomer.length > 0) {
          const app = this;
          if(this.selectedCustomer.length === 1){
            this.loading = true;
            this.qrSendByUser(app.selectedCustomer[0], app.request).then(resp=>{
              if(resp.data){
                app.notice.success(`Đã gửi tin nhắn - Thành công: ${resp.data['success']} - Thất bại: ${resp.data['failure']}`);
              }else{
                app.notice.error(resp.error);
              }
            }).finally(()=>{
              this.loading = false;
            });
          }else {
            this.sendState = {};
            let Tasks = [];
            this.selectedCustomer.forEach(user => {
              this.sendState = Object.assign(this.sendState, {[user]: `Đang xử lí...`});
              Tasks.push(app.doSendByUser(user));
            });
            this.loading = true;
            Promise.all(Tasks).catch(err=>{
              this.notice.error(err);
            }).finally(()=>{
              this.loading = false;
            });
          }
        }
      }
    },
    doSendByUser(user){
      return this.qrSendByUser(user, this.request).then(resp=>{
        if(resp.data){
          this.sendState = Object.assign(this.sendState, {[user]: `Thành công: ${resp.data['success']} - Thất bại: ${resp.data['failure']}`});
        }else{
          this.sendState = Object.assign(this.sendState, {[user]: `Lỗi: ${resp.error}`});
        }
      });
    },
    doRemoveCustomerList(item){
      this.selectedCustomer.splice(this.selectedCustomer.indexOf(item), 1);
    },
    doFetch(){
      return this.qrFindCustomer().then(json=>{
        if(json.data){
          this.listCustomer = json.data.map(item=>({
            text: item.Username,
            value: item._id
          }));
        }
      });
    }
  },
  mounted() {
    this.doFetchData();
    this.doFetch();
  }
}
</script>

<style scoped>

</style>