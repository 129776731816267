import { Request } from '@/plugins/helper/fetch'
const req = new Request('snotification');
const qrList = () =>{
  return req.get('list').then(resp=>resp.json());
}
const qrFindCustomer = () =>{
  return req.get('users').then(resp=>resp.json());
}
const qrSendAll = (request) =>{
  return req.put('send-all', {request}).then(resp=>resp.json());
}
const qrSendByUser = (id, request) =>{
  return req.put(`send/${id}`, {request}).then(resp=>resp.json());
}
export default {
  qrList,
  qrFindCustomer,
  qrSendAll,
  qrSendByUser,
}